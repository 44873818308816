import { ChangeDetectionStrategy, Component, computed, input, inject } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MobiUiIconComponent, MobiUiIconsRegistry } from '@mobi/rwc-ui-components-ng-jslib';

import { mobiIconTheX, mobiIconTik } from '@mobi/rwc-utils-icons-jslib';

import { TABLE_EXCLUDED, TABLE_INCLUDED } from '@mobi/oiv-viewer-utils-ng-jslib';

import { Helper } from '../../helper';
import { ByPassSecurityPipe } from '../../../util/pipes/by-pass-sercurity.pipe';

@Component({
    selector: 'oiv-viewer-paragraph',
    standalone: true,
    imports: [CommonModule, ByPassSecurityPipe, MobiUiIconComponent],
    templateUrl: './paragraph.component.html',
    styleUrl: './paragraph.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParagraphComponent {
    #helper = inject(Helper);

    eId = input('');
    cssClass = input('mobi-ui-font-size-150');
    useOriginalStyle = input(false);
    isAnchorList = input(false);
    content = input<string>('');
    isTableIncluded = computed(() => this.content().includes(TABLE_INCLUDED));
    isTableExcluded = computed(() => this.content().includes(TABLE_EXCLUDED));
    transformedContent = computed(() => {
        let content = this.#helper.handleNewLine(this.content(), this.isAnchorList());
        content = this.#helper.parseLink(content);
        content = this.#helper.parseWithBoldItalicXmlTags(content);
        return content;
    });

    constructor() {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconTheX, mobiIconTik]);
    }
}
