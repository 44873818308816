@if (isTableIncluded()) {
    <div class="mobi-ui-centered">
        <mobi-ui-icon-major size="lg" name="tik" />
    </div>
} @else if (isTableExcluded()) {
    <div class="mobi-ui-centered">
        <mobi-ui-icon-major size="lg" name="the_x" />
    </div>
} @else {
    <p
        class="mobi-ui-margin-b-xxxs oiv-text"
        [ngClass]="cssClass()"
        [attr.id]="eId()"
        [innerHTML]="transformedContent() | byPassSecurity"
    ></p>
}
